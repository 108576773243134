.login-logo {
    background-size: 52% 80%;
}

.total-number {
    float: left;
    margin-right: 5px;
}

.navbar .navbar-brand small img {
    margin-top: 6px !important;
    height: 31px !important;
    width: 135px !important;
}
